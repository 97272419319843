<template>
  <div>
    <b-row>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Başlangıç Tarihi"
          rules="required"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="start_date"
          >
            <b-input-group>
              <b-form-datepicker
                id="start_date"
                v-model="dataItem.start_date"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="dataItem.start_date">
                <b-button
                  variant="outline-primary"
                  @click="dataItem.start_date = null"
                >
                  <FeatherIcon icon="XCircleIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Bitiş Tarihi"
          rules="required"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="finish_date"
          >
            <b-input-group>
              <b-form-datepicker
                id="finish_date"
                v-model="dataItem.finish_date"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="dataItem.finish_date">
                <b-button
                  variant="outline-primary"
                  @click="dataItem.finish_date = null"
                >
                  <FeatherIcon icon="XCircleIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Başlangıç Saati"
          rules="required"
        >
          <b-form-group
            label="Başlangıç Saati"
            label-for="start_time"
          >
            <b-form-timepicker
              id="start_time"
              v-model="dataItem.start_time"
              :locale="locale"
              v-bind="labels[locale] || {}"
              @context="onContext"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Bitiş Saati"
          rules="required"
        >
          <b-form-group
            label="Bitiş Saati"
            label-for="finish_time"
          >
            <b-form-timepicker
              id="finish_time"
              v-model="dataItem.finish_time"
              :locale="locale"
              v-bind="labels[locale] || {}"
              @context="onContext"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BFormDatepicker, BFormGroup, BInputGroup, BInputGroupAppend, BCol, BRow, BFormTimepicker,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'StartFinishDatePicker',
  components: {
    BFormTimepicker,
    ValidationProvider,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormGroup,
    BCol,
    BRow,
  },
  data() {
    return {
      locale: 'tr',
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'tr', text: 'Türkçe (tr)' },
      ],
      labels: {
        tr: {
          labelHours: 'Saat',
          labelMinutes: 'Dakika',
          labelSeconds: 'Saniye',
          labelIncrement: 'Arttır',
          labelDecrement: 'Azalt',
          labelSelected: 'Seçildi',
          labelNoTimeSelected: 'Saat Seçiniz',
          labelCloseButton: 'Seç',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
  },
}
</script>
