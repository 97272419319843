<template>
  <div>
    <b-form-group
      label="Yerine Bakacak Kişi"
      label-for="r_id_com_user"
    >
      <v-select
        id="r_id_com_user"
        v-model="dataItem.r_id_com_user"
        label="name"
        :reduce="item => item.id"
        :options="personelList"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div v-if="option.department">
            {{ option.name }}
            <div>
              <small class="text-warning">{{ option.department }}</small>
              <div v-if="option.brand">
                <small class="text-primary">{{ option.brand }}</small>
              </div>
            </div>
          </div>
          <div v-else>
            {{ option.name }}
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div v-if="option.department">
            {{ option.name }}
            <small class="text-warning">{{ option.department }}</small>
          </div>
          <div v-else>
            {{ option.name }}
          </div>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'SparePersonelCard',
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      locale: 'tr',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
    personelList() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id as id',
          'com_user.name as name',
          'com_department.title  as department',
          'com_brand.name  as brand',

        ],
        where: {
          // 'com_user.id_com_brand': this.userData.id_com_brand,
          // 'com_user.id_com_department': this.userData.id_com_department,
          'com_user.status': 1,
        },
      })
    },
  },
}
</script>
