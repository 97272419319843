<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="İzin Tipi"
      rules="required"
    >
      <b-form-group
        label="İzin Tipi"
        label-for="id_com_permit_request_type"
      >
        <v-select
          id="id_com_permit_request_type"
          v-model="dataItem.id_com_permit_request_type"
          label="title"
          :reduce="item => item.id"
          :options="typeList"
          placeholder="Seçiniz"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'PermitTypeCard',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
    typeList() {
      return this.$store.getters['permitRequestType/getDataList']
    },
  },
  created() {
    this.getTypes()
  },
  methods: {
    getTypes() {
      this.$store.dispatch('permitRequestType/dataList')
    },
  },
}
</script>
