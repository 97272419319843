<template>
  <div>
    <b-row>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="İzin Nedeni"
          rules="required"
        >
          <b-form-group
            label="İzin Nedeni"
            label-for="permit_reason"
          >
            <b-form-textarea
              v-model="dataItem.permit_reason"
              placeholder="Giriniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormGroup, BCol, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'PermitReasonCard',
  components: {
    ValidationProvider,
    BFormGroup,
    BCol,
    BRow,
    BFormTextarea,
  },
  data() {
    return {
      locale: 'tr',
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'tr', text: 'Türkçe (tr)' },
      ],
      labels: {
        tr: {
          labelHours: 'Saat',
          labelMinutes: 'Dakika',
          labelSeconds: 'Saniye',
          labelIncrement: 'Arttır',
          labelDecrement: 'Azalt',
          labelSelected: 'Seçildi',
          labelNoTimeSelected: 'Saat Seçiniz',
          labelCloseButton: 'Seç',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
  },
}
</script>
