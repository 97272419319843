<template>
  <div>
    <start-finish-date-picker />
    <b-row>
      <b-col>
        <permit-type-card />
      </b-col>
      <b-col>
        <spare-personel-card />
      </b-col>
    </b-row>
    <permit-reason-card />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'

import StartFinishDatePicker from '@/views/Permit_request/elements/StartFinishDatePickerCard.vue'
import PermitReasonCard from '@/views/Permit_request/elements/PermitReason.vue'
import PermitTypeCard from '@/views/Permit_request/elements/PermitType.vue'
import SparePersonelCard from '@/views/Permit_request/elements/SparePersonel.vue'

export default {
  name: 'PermitForm',
  components: {
    SparePersonelCard,
    PermitTypeCard,
    PermitReasonCard,
    StartFinishDatePicker,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
  },
}
</script>
