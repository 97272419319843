<template>
  <b-card title="İzin Talebi Oluştur">
    <validation-observer ref="simpleRules">
      <PermitForm />
    </validation-observer>
    <div class="text-right">
      <b-button
        variant="success"
        class="text-right"
        :disabled="dataItem.submitStatus"
        @click="submitData"
      >
        <feather-icon icon="SendIcon" />
        Onaya Gönder
      </b-button>
    </div>
  </b-card>
</template>
<script>
import { BButton, BCard } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PermitForm from '@/views/Permit_request/components/permitForm.vue'

export default {
  name: 'PermitRequestAdd',
  components: {
    BButton,
    PermitForm,
    BCard,
    ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
    saveStatus() {
      return this.$store.getters['permitRequest/saveDataStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'İzin talebi yöneticinize başarı ile iletildi.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push({ name: 'PermitRequestList' })
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    this.$store.commit('permitRequest/SET_DATA_RESET')
  },
  methods: {
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_com_permit_request_status = 1
          this.dataItem.id_com_user_manager = this.userData.id_com_user_manager
          this.dataItem.id_com_user_department = this.userData.id_com_department
          this.dataItem.id_com_user_brand = this.userData.id_com_brand
          this.$store.dispatch('permitRequest/saveData', this.dataItem)
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
